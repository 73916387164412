export default {
    "url":"",
    "edoctusUrl":"",
    "version":18,
    "edocutusDocumentTypeId":6,
    "edocutusDatabase":"NG UAT",
    "secretPhrase": "2348hsulhdfhgulewnrflksfd",
    "reportUrl":"https://app.powerbigov.us/groups/me/apps/5d747e08-05b7-49cc-aca4-97220b7bff48/reports/237f5660-658e-4fda-acb5-c925a09324d2/ReportSection?ctid=5ec1d8f0-cb62-4000-b327-8e63b0547048",
    "VehSrchStaticText":"No Vehicle Search History found for the entered Reimbursement Period. User is ineligible for Mileage Expense Reimbursement.",
    "logoutUrl":"https://tripstst.dhs.arkansas.gov/",
    "cvtUrl":"https://lehon.dhhs.arkgov.net/ofa/CVT/",
    "CheckMFA": "YES"
}